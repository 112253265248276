import { useCallback, useEffect, useState } from 'react';

/**
 * @typedef {{key: string, type: 'alert'|'warning'|'success', title: string, message: string, linkText?: string, linkUrl?: string, persistent?: boolean}} Message
 */

/**
 * React hook to host messages and allow addition and removal
 *
 * @returns {[messages: object[], addMessages: (message: Message|Message[]) => {}, removeMessages: (type) => {}, resetBillingMessages: () => {}]}
 */
 export function useMessages() {
    const [messages, setMessages] = useState([]);

    // Ensure we momoize the callbacks so they don't "change" and trigger useEffect calls if they get passed as a dependency
    return [
        messages,
        // addMessages
        useCallback(message => {
            if (Array.isArray(message)) {
                setMessages(oldMessages => [...oldMessages.filter(oldMessage => !oldMessage.key || !message.find(newMessage => newMessage.key && newMessage.key === oldMessage.key)), ...message]);
            } else {
                setMessages(oldMessages => [...oldMessages.filter(oldMessage => !oldMessage.key || !message.key || oldMessage.key !== message.key), message]);
            }
        }, []),
        // removeMessages
        useCallback(type => {
            if (type) {
                setMessages(oldMessages => [...oldMessages.filter(message => message.key != type)]);
            } else {
                setMessages([]);
            }
        }, []),
        // resetBillingMessages
        useCallback(() => {
            setMessages(oldMessages => [...oldMessages.filter(message => !['billing_failure', 'payment_expire', 'payment_invalid'].includes(message.key))]);
        }, []),
    ];
}

/**
 * React hook to automatically add billing messages when they change
 *
 * @param {{messages: object[]}} loginState
 * @param {() => {}} resetBillingMessages
 * @param {(messages) => {}} addMessages
 * @returns void
 */
export function useBillingMessages(resetBillingMessages, addMessages, loginState) {
    useEffect(() => {
        resetBillingMessages();
        if (loginState?.messages) {
            addMessages(loginState.messages);
        }
    }, [resetBillingMessages, addMessages, loginState]);
}
