import { useTitle } from 'hooks';

export default function Terms() {
    useTitle('Terms and Conditions');

    return (
        <div className="section-8 wf-section">
            <h1 className="heading-2">MARINERS TV TERMS &amp; CONDITIONS</h1>
            <p className="paragraph-7">These terms and conditions (&quot;Terms&quot;) apply to the marinerstv.co.uk premium digital content service (&quot;marinerstv.co.uk&quot;) accessed through the Club official website (&quot;Site&quot;). These Terms apply to all use and access of marinerstv.co.uk whether paid or provided on a free or promotional basis.<br />
            <br />
            <strong>Please read these Terms carefully before submitting your order.<br />
            </strong><br />
            Access to marinerstv.co.uk is provided to you, the subscriber (&quot;you&quot;, &quot;your&quot;) on the basis that you pay the Fee (as per the methods detailed below) and accept the terms of this contract.<br />
            Please note that your access to marinerstv.co.uk will also be subject to the Terms and Conditions and applicable Privacy Policy, all of which (as updated over time) are incorporated into these Terms and form the contract.<br />
            <br />
            The contract is a legal agreement and it sets out the rights and obligations of you and marinerstv.co.uk (&quot;marinerstv.co.uk&quot;, &quot;we&quot;, &quot;us&quot;, &quot;our&quot;).<br />
            <br />
            <strong>1. Registration and Username / Password<br />
            <br />
            </strong>1.1. Once you have submitted your order to us, we will email you to confirm acceptance, at which point the contract between you and us will be formed. We may retain a copy of the contract details for our own records. If we cannot accept your order we will inform you by email. This may be because, for example (i) there has been a pricing error (see section 3) (ii) you failed to provide required information (see section 1.3) (iii) you have tried to order a subscription package which is not available in your territory or for which you are otherwise not eligible (iv) there has been an error in your initial payment of the subscription fee or (v) we have been affected by a recent unforeseen event which means marinerstv.co.uk cannot be made available in whole or part.<br />
            <br />
            1.2. By submitting your order to us you confirm that:<br />
            <br />
            1.2.1. you are at least 18 years old;<br />
            <br />
            1.2.2. you are solely resident in the correct territory for your chosen subscription package. Please note content included in some subscription packages is only available in certain territories due to rights restrictions. Change to the territory in which you are located may affect availability of certain content; and<br />
            <br />
            1.2.3. the information which you provide is true, accurate and complete in all respects at that time. You must also notify us immediately of any changes to your information during your subscription by either updating the details in the My Account page of the Site or emailing marinerstv@streamamg.com. As above, changes to your located territory may affect content access.<br />
            <br />
            1.3. If we find that you have provided incorrect or inadequate information, your order may not be accepted and/or we may ask you to provide the correct and complete information. If you still fail to do so within a reasonable time, we may reject your order or end the contract (in the case of your error being uncovered after we have already accepted your order – see section 8). We will not be responsible for delay or failure in providing you access to all or part of marinerstv.co.uk if this is caused by you failing to provide us with the information needed and/or updating us of material changes to your information.<br />
            <br />
            1.4. If you wish to upgrade your subscription package after your order has been accepted, you can do so through your &quot;My Account&quot; section of marinerstv.co.uk. We will let you know if the upgrade is possible and if so the applicable changes, including any increase in price and when the upgrade may take effect. You may be asked to confirm this change through the Site or email.<br />
            <br />
            1.5. On registration you will be required to enter a user name and password. You must keep the password secure and not disclose it to any other person. Your right to use marinerstv.co.uk is personal to you and is limited to viewing on one device at any time. If your password becomes known by a third party, you must immediately change your password by logging into the &quot;My Account&quot; section of marinerstv.co.uk. We are not liable for any loss or damage arising from your failure to comply with this obligation.<br />
            <br />
            <strong>2. Use of the Service<br />
            </strong><br />
            2.1. We provide you with access to various types of digital content within marinerstv.co.uk as set out for the subscription package you selected on your order. This may include, for example, audio/video coverage of matches, statistics, photo galleries and/or video streaming of interviews and highlights. Your access is not exclusive (others can access the content too), may be suspended and/or terminated in accordance with these Terms, and may depend on the territory in which you are located or other applicable eligibility criteria set out in the order process.<br />
            <br />
            2.2. marinerstv.co.uk content includes certain video and audio footage, photographs, text images, statistics, logos and other media and intellectual property related to the Club, the English Football League, Football Association, Premier League, us and the respective content licensees of those parties. All such content, design, text, graphics, footage, materials and their selection or arrangement are the intellectual property rights (including, amongst other things) copyright of us, the above parties and/or the respective licensees. You will not own any of the content.<br />
            <br />
            2.3. For match footage shown on a live basis (certain geographical territories only) not all Club matches will be available for viewing on a &quot;live&quot; basis. This is due to, amongst other things, restrictions with television broadcasters. Any game which is selected for live TV broadcast by an EFL partner broadcaster, whether in the UK or internationally, cannot be shown via this service. Games which are not selected for TV broadcast can be viewed in international territories outside the UK and Ireland. Games which are not selected for TV broadcast and do not kick off between 12.45pm and 5.15pm on a Saturday, or overlap in any way with these hours, can be shown in the domestic market (UK and Ireland) via this service.<br />
            <br />
            2.4. marinerstv.co.uk is a content access service only. We are not providing, and are not responsible for any problems caused by your computer hardware, computer operating systems, internet connection or other software installed on your computer (and/or any traders you engage with in respect of such resources).<br />
            <br />
            2.5. marinerstv.co.uk has not been developed to meet your individual needs. Please check that facilities and functions of marinerstv.co.uk (as described on the Site) meet your requirements.<br />
            <br />
            2.6. In order to access and use marinerstv.co.uk, you will need to:<br />
            <br />
            2.6.1. provide all necessary equipment including a computer and network / telephone connection;<br />
            <br />
            2.6.2. access to the internet, and you are responsible for any service fees associated with such access. You will require a WIFI or broadband internet connection (DSL/cable or higher) with a minimum download speed of at least 1.2mbps with latency not exceeding 100ms for SD and 4mbps with latency not exceeding 50ms for HD.<br />
            <br />
            2.7. For use of marinerstv.co.uk your device needs to comply with the following minimum system requirements (as may be updated occasionally, for which see section 3 below):<br />
            <br />
            2.7.1. Processor: 2.33GHz or faster x86-compatible processor, or Intel® Atom™ 1.6GHz or faster processor for netbooks;<br />
            <br />
            2.7.2. Memory: 2GB (1GB for netbooks) 128MB of graphics memory.<br />
            <br />
            2.7.3. Operating Systems: Windows 8.1 or later, Mac 9+<br />
            <br />
            2.7.4. Browsers: latest versions of Microsoft Edge, Mozilla Firefox and Google Chrome, Safari; 512MB of RAM (1GB of RAM recommended for netbooks)<br />
            <br />
            2.7.5. Mobiles &amp; Tablets: Operating System: Android 6+, iOS 9+<br />
            <br />
            2.8. You must not (nor authorise or permit any other person to):<br />
            <br />
            2.8.1. use marinerstv.co.uk contrary to this contract, including use for any unlawful purpose contrary to any applicable laws and regulations, including in the country from which you have accessed marinerstv.co.uk;<br />
            <br />
            2.8.2. use marinerstv.co.uk other than for private and domestic purposes. Under no circumstances must marinerstv.co.uk nor any content be accessed or used for commercial or business purposes, without our prior written consent;<br />
            <br />
            2.8.3. transmit any computer viruses or any other disruptive or harmful contaminants through marinerstv.co.uk or the technology on which they rely;<br />
            <br />
            2.8.4. use marinerstv.co.uk in a way that may cause it and/or any equipment used by us (or our technology partners appointed to help provide marinerstv.co.uk) to be interrupted, damaged, rendered less efficient or impaired, nor try to gain unauthorised access to any of systems through which marinerstv.co.uk is delivered;<br />
            <br />
            2.8.5. store your password anywhere on a computer in plain text;<br />
            <br />
            2.8.6. use marinerstv.co.uk in any manner which violates or infringes the rights of any person, firm or company (including, amongst other things, rights of intellectual property, confidentiality or privacy);<br />
            <br />
            2.8.7. forward, record, copy, reproduce, store, transfer, modify, post, distribute or publish any of the content of marinerstv.co.uk without our prior written permission (unless otherwise specifically permitted by law);<br />
            <br />
            2.8.8. sell, assign, transfer or delegate all or any of your rights and obligations in this contract to another person or organisation, or share use of or access to your marinerstv.co.uk account or any content contained or accessed within it;<br />
            <br />
            2.8.9. alter, disassemble, decompile or reverse engineer any part of marinerstv.co.uk; or<br />
            <br />
            2.8.10. access, view or use marinerstv.co.uk in circumstances where members of the public can view the content accessible via your account (whether simultaneously or not) or authorise any other person to do so.<br />
            <br />
            2.8.11. Use a VPN (Virtual Private Network) service or similar to mask or otherwise hide your location for the purposes of watching content in a country or territory where it is blocked for rights reasons.<br />
            <br />
            <strong>3. Changes &amp; Updates<br />
            </strong><br />
            3.1. We may change marinerstv.co.uk and/or these Terms without giving you notice in order to:<br />
            <br />
            3.1.1. reflect changes in relevant laws or regulatory requirements;<br />
            <br />
            3.1.2. implement minor or emergency technical adjustments, for example to address a security threat;<br />
            <br />
            3.1.3. alter or improve the presentation of the user interface, or increase functionality (where this does not increase the price payable by you for your current subscription period);<br />
            <br />
            3.1.4. implement and reflect planned changes we have prominently and specifically drawn to your attention when or before you submitted your order, or (in relation to any renewal) as part of your renewal reminder, referred to at section 5; or<br />
            <br />
            3.1.5. implement other minor changes which would not reasonably be likely to cause you a material detriment in your use of marinerstv.co.uk nor increase the price payable by you in your current subscription, or which we reasonably believe to be for the benefit of users.<br />
            <br />
            3.2. For more significant planned changes to marinerstv.co.uk and/or these Terms, you will be provided with reasonable notice of the planned change. For example, this may include:<br />
            <br />
            3.2.1. removal of outdated or redundant features from marinerstv.co.uk or<br />
            <br />
            3.2.2. detrimental changes to the nature of or locations in which certain types of content relevant to your marinerstv.co.uk subscription are available. This is sometimes required to reflect changes in football (soccer) league/teams appointed broadcasters from time to time.<br />
            After receiving such notice, you may then contact us before the change is implemented to end your subscription if you do not wish to be bound by the change, in which case your subscription will end at the end of that calendar month or expiry of your current subscription period (whichever is sooner) and you will receive a refund for any outstanding period of your subscription for which you have already paid. If you do not contact us to unsubscribe before the change is implemented, you will be deemed to have agreed to the change.<br />
            <br />
            3.3. We may update the underlying software relating to marinerstv.co.uk, or require you to implement updates from time to time to continue accessing marinerstv.co.uk. For example, as time goes on you may need to ensure your system remains compatible with technical requirements (see section 2).<br />
            <br />
            3.4. If any change to these Terms or changes carried out in accordance with them is found invalid, void or for any reason unenforceable, only that change will be disregarded and it will not affect the validity and enforceability of any remaining changes or Terms.<br />
            <br />
            <strong>4. Payment<br />
            </strong><br />
            4.1. You must pay the subscription fee applicable to the time period you have subscribed for (&quot;Subscription Period&quot;), in accordance with the fee plan notified to you at the time of registration and confirmed in our acceptance email sent to you (the &quot;Fee&quot;).<br />
            <br />
            4.2. Except in the case of obvious error the Fee is as set out on the Site at the time of your application for an marinerstv.co.uk subscription. If we accept and process your order where a price error is obvious and unmistakable and could reasonably have been recognised as such by you, we may end the contract and refund you any sums paid.<br />
            <br />
            4.3. Unless otherwise stated, all Fees are inclusive of VAT or other similar sales tax in your territory. If the rate of VAT or similar sales tax in your territory changes between your order date and the end of the Subscription Period, we will adjust the rate on subsequent instalments of the Fee outstanding on your payment plan.<br />
            <br />
            4.4. You are responsible for any other additional local taxes or duties applicable to the territory in which you reside or otherwise access marinerstv.co.uk from.<br />
            <br />
            4.5. All payments must be in UK pounds sterling, unless we have expressly set out an alternative currency by which you may pay in the order process or any renewal notice. We cannot accept payment in currencies other than those expressly noted at the time you submit your order or in any subsequent renewal notice.<br />
            <br />
            4.6. You may pay the Fee in a number of ways:<br />
            <br />
            4.6.1. Credit/Debit card. The Fee will be automatically billed against the credit/debit card number that you provide in the registration form. Payment may be made by any of the following credit, debit or prepaid cards: Visa, Mastercard or America Express.<br />
            <br />
            4.6.2. Direct Debit. If you elected to pay by direct debit (only where we have expressly offered this as a payment option during your subscription order process, or as part of any subsequent renewal notice) we will debit the bank account which you provided the details for. The amounts to be debited will be as set out in the fee plan notified to you.<br />
            <br />
            4.6.3. PayPal. You must provide your valid PayPal account details and the Fee will be billed against that PayPal account as set out in the fee plan notified to you. Please note Paypal is not available yet.<br />
            <br />
            4.6.4. Voucher. From time to time we or the Club may issue vouchers which will provide access to marinerstv.co.uk in accordance with the terms set out in the vouchers. Any additional terms and conditions applicable to the access provided by the voucher will be set out on the voucher. In the event of conflict with these Terms, the terms of the voucher will take priority.<br />
            <br />
            4.7. We do not accept any responsibility for payments which are not received by us or accepted by your bank, other than where directly due to our fault.<br />
            <br />
            4.8. We have contracted with Stream AMG to provide the streaming services that provide You (the subscriber who pays a fee) with the ability to access and view our content. The payment processing relating to the ability to view the content may also be provided by Stream AMG on our behalf.<br />
            Payment for the ability to view our content is made to Stream AMG on our behalf, based upon the subscription plan that you sign up for. The subscription fee will be processed through Stream AMG and will be processed in a secure manner by a third party payment services provider retained by Stream AMG. Questions concerning payment processing can be directed to marinerstv@streamamg.com<br />
            <br />
            <strong>5. Automatic Renewal<br />
            </strong><br />
            5.1. This section 5 does not apply to any single match or other express pay per view arrangement.<br />
            <br />
            5.2. Your subscription is for the Subscription Period and will, unless we notify you otherwise, be automatically renewed at the end of the previous Subscription Period for a further equivalent period.<br />
            <br />
            5.3. Monthly Subscriptions: We will not normally send you reminders of renewal, unless there are changes in your renewal period you need to be aware of (see section 3 above). You can unsubscribe from marinerstv.co.uk with effect from the end of your monthly Subscription Period (or relevant renewal month) by contacting us at marinerstv@streamamg.com not less than 5 days prior to the date of renewal. Your subscription will continue until the end of the month in which you notify us in accordance with this section and you will continue to be charged for that month.<br />
            <br />
            5.4. Other Subscriptions: The following process will apply to your renewal:<br />
            <br />
            5.4.1. a reminder will be sent to you by email at least 21 days before the end of your last Subscription Period reminding you that your subscription will automatically renew at the end of the current Subscription Period;<br />
            <br />
            5.4.2. at the same time as that reminder, you will also be informed of:<br />
            <br />
            5.4.2.1. the terms and conditions applicable to the renewed Subscription Period;<br />
            <br />
            5.4.2.2. the price for that renewal period, if different from that for the current Subscription Period;<br />
            <br />
            5.4.2.3. action you need to take if you do not want your subscription to be automatically renewed. Usually this will require you to email confirmation that you want to unsubscribe to marinerstv@streamamg.com not less than 5 days prior to the date of renewal;<br />
            <br />
            5.4.2.4. any other information we believe may be relevant to the renewed Subscription Period; and<br />
            <br />
            5.4.3. if your subscription is renewed as above (and you opted to receive your renewed subscription benefits immediately, waiving your right to cancel) you may only cancel the contract where we are not at fault if you provide us with at least 30 days&#x27; notice of your desire to cancel. The contract will not end until the end of the calendar month in which that notice expires. You will be entitled to a pro-rata refund of any sums you have paid relating to the period after termination, less an administration charge of ten per cent (10%) of that refund value.<br />
            <br />
            5.5. Unless you have opted to unsubscribe from marinerstv.co.uk not less than 5 days prior to the date of renewal your subscription will be automatically renewed. You will be deemed to have accepted the renewal on the terms and basis set out in the reminder (or, for monthly subscriptions where we have not notified you in advance of changes, on the same terms as your previous subscription). You will be charged in accordance with the same timings as under the payment plan applicable to your immediately preceding Subscription Period, unless otherwise notified to you as part of any reminder above.<br />
            <br />
            5.6. If you have unsubscribed from marinerstv.co.uk rather than renewing and you previously paid by direct debit, you are responsible for ensuring that you cancel your direct debit through your bank in order to prevent any future payments being taken by us. Save for returning payments received by us in error on request from you, we will not accept any liability for payments taken from your bank account as a result of your failure to cancel the direct debit instruction at your bank, including (for example only) interests charges and other fees.<br />
            <br />
            <strong>6. Cancellation and Refunds – Please read carefully<br />
            </strong><br />
            6.1. By submitting your order you have opted to receive your subscription benefits immediately and waived your right to cancel. You will not therefore be entitled to cancel the contract or have any fourteen (14) day &quot;cooling off&quot; period in law, nor receive a refund of the Fee. This does not affect any other rights you may have at law, including where we are at fault.<br />
            <br />
            6.2. See section 5.4.3 above regarding cancellation after any automatic renewal.<br />
            <br />
            6.3. When you acquire a subscription, you acknowledge and agree to all terms and conditions and auto-renewal of your subscription. You may cancel your subscription for the services by logging in and going to the My Account page and clicking on the &quot;Cancel&quot; button. You may cancel your subscription to the services at any time prior to the expiry of your subscription period, but you will continue to have access to the services for the duration of your subscription period. We do not refund any subscription fees paid in case of your cancellation or cancellations that take place after the fourteen-day cooling off period.<br />
            <br />
            <strong>7. Disclaimers and Liability<br />
            </strong><br />
            7.1. Neither we, the Club nor any of our staff or other representatives will be responsible or liable to you for any loss, damage, or inability to access and/or use marinerstv.co.uk which:<br />
            <br />
            7.1.1. is due to any use you make of marinerstv.co.uk, other than that we permit under this contract;<br />
            <br />
            7.1.2. is due to events outside our reasonable control. For example (and amongst other things) this may include fire, floods, severe weather, terrorist activity or civil disruption;<br />
            <br />
            7.1.3. is due to incompatibility of your devices or systems with the compatibility and technical requirements we have informed you of (see section 2 above);<br />
            <br />
            7.1.4. is caused by viruses, Trojans, worms or other harmful data not caused by or attributable to an error or problem with marinerstv.co.uk or www.safc.com;<br />
            <br />
            7.1.5. is caused by your failure to follow any reasonable, clear and easy to follow instructions we have made known to you relating to your use of marinerstv.co.uk;<br />
            <br />
            7.1.6. concerns loss or damage:<br />
            <br />
            7.1.6.1. which is not a foreseeable result of our breach of these Terms (though we will be responsible for loss or damage which is foreseeable). Loss of damage is foreseeable if either it is obvious that it will happen or if, at the time we enter this contract, both you and we knew it might happen; or<br />
            <br />
            7.1.6.2. relating to any business, including (amongst other things) loss of profits, loss of business, loss of opportunity and/or business interruption. marinerstv.co.uk is made available for private and domestic purposes only.<br />
            <br />
            7.2. We do not limit or exclude our liability for death or personal injury resulting from our negligence; fraud; or for breach of your mandatory legal rights (for example, including our liability in UK consumer contract law if marinerstv.co.uk digital content is not of satisfactory quality, fit for purpose, in compliance with its description or we are found to not have rights to provide such content). You should seek your own legal advice in relation to any such rights at law.<br />
            <br />
            <strong>8. Suspension and Termination<br />
            </strong><br />
            8.1. We may suspend or terminate your access to all or part of marinerstv.co.uk at any time if:<br />
            <br />
            8.1.1. (in the case of suspension only) you do not make payment to us when it is due;<br />
            <br />
            8.1.2. (in the case of termination) you do not make payment to us when it is due (or such payment has failed or been refused by your payment service provider), and still fail to do so within 10 days after having had a reminder by email that it is due;<br />
            <br />
            8.1.3. we are unable to verify or authenticate any information you have provided to us, where we reasonably need to do so (or information you have provided is incomplete) and you do not, within a reasonable time of us asking for it, provide us with required information that is necessary for us to start or continue making all or part of marinerstv.co.uk available to you;<br />
            <br />
            8.1.4. you commit a serious breach of this contract, or commit a less serious breach which can be fixed and you have failed to fix it within 20 days of us notifying and requiring you to do so; or<br />
            <br />
            8.1.5. your use of marinerstv.co.uk is reasonably considered abusive, excessive, or against the interests of other subscribers or persons,<br />
            <br />
            8.1.6. You are suspected to be using a VPN to access the platform<br />
            and in each case no refund will be provided.<br />
            <br />
            8.2. In addition we may close marinerstv.co.uk on service of not less than 30 days&#x27; notice. If this occurs part way through your subscription, you will receive a pro-rata refund of any Fees you have already paid relating to the period of your current Subscription Period remaining as at the date of termination.<br />
            <br />
            8.3. Subscribers who are found to be using the service for anything other than personal use will have their access revoked and be permanently banned with no refund.<br />
            <br />
            <strong>9. Maintenance<br />
            <br />
            </strong>From time to time, marinerstv.co.uk may be taken down and your access to all or part of it suspended in order for work to be carried out relating to the upgrading or maintenance as necessary for the provision of marinerstv.co.uk. We shall give as much notice as is reasonable in the circumstances and shall endeavour to ensure that such works are carried out as expeditiously as possible in the circumstances, at times which minimise inconvenience to you. This may not be possible in cases of emergency to rectify significant faults, prevent further risk to you, other users or marinerstv.co.uk technology.<br />
            <br />
            <strong>10. General<br />
            </strong><br />
            10.1. Any notices we send will be sent to the email address you supply during the registration process. We ask that any notices you send to us are sent by email to marinerstv@streamamg.com. Notices will be deemed to have been delivered at the time and date of sending of the email, which time and date are specified in the email.<br />
            <br />
            10.2. If we fail or delay to exercise or enforce any right we have under these Terms such failure or delay will not be deemed to be a waiver of that right nor will it prevent us exercising or enforcing that right on a later occasion.<br />
            <br />
            10.3. This contract is between you and us. No-one else will have any rights to enforce its terms.<br />
            <br />
            10.4. We may transfer our rights and obligations under this agreement to another organisation. We will always let you know if this happens and will ensure that the transfer does not affect your rights under this contract. Please note you need our consent to transfer your rights and obligations under this contract to anyone else.<br />
            <br />
            10.5. This contract shall be governed by, and interpreted exclusively in accordance with, English law. Any dispute arising under this contract shall be subject to the exclusive (non-exclusive in respect of consumers resident outside England) jurisdiction of the English courts.<br />
            <br />
            10.6. In addition please note that disputes may also be submitted for online resolution to the European Commission Online Dispute Resolution platform.<br />
            <br />
            <strong>11. Complaints<br />
            </strong><br />
            11.1. If you have any questions or complaints about marinerstv.co.uk, please contact us at marinerstv@streamamg.com<br />
            <br />
            11.2. We will endeavour to respond to any complaint within 7 working days of receipt of such communication. If it is not possible to provide a full response to the issue raised within that time, an acknowledgement will be sent and a detailed reply will follow within 21 working days of receipt of the original communication.<br />
            <br />
            11.3. We will attempt to resolve all justifiable complaints within 28 working days. If, however, this cannot be done, we will keep you updated on the progress of the complaint.<br />
            <br />
            11.4. if you are having technical issues and require a refund please ensure that you have notified us of a problem to marinerstv@streamamg.com during the game with details of the problem, screenshots if possible and information about the devices and browsers you are using. If we are notified during the game we can make attempts to investigate and rectify the problem, issues reported after the match will not qualify for a refund.<br />
            <br />
            11.5. If you are unable to view the game because you are using a VPN or smartphone / tablet device you will not be eligible for a refund as this is clearly stated as a condition of use at the point of purchase.<br />
            <br />
            11.6. If you are unable to view the game due to a technical error that is the responsibility of the Club or our providers for more than 20 minutes you will be eligible for a refund.</p>
        </div>
    );
}
