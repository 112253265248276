import { useCallback } from 'react';

export default function Pagination(props) {
    const { pageCount, currentPage, setCurrentPage } = props;

    const onClickPrevious = useCallback(() => {
        setCurrentPage(currentPage - 1);
    }, [currentPage, setCurrentPage]);

    const onClickNext = useCallback(() => {
        setCurrentPage(currentPage + 1);
    }, [currentPage, setCurrentPage]);

    if (pageCount < 2) {
        return null;
    }

    return (
        <div className="columns-center">
            {currentPage > 0 ? <button className="button-4 w-button" onClick={onClickPrevious}><span aria-hidden="true">&lt;</span> Previous</button> : null}
            {currentPage > 0 && currentPage < pageCount - 1 ? <>&nbsp;</> : null}
            {currentPage < pageCount - 1 ? <button className="button-4 w-button" onClick={onClickNext}>Next <span aria-hidden="true">&gt;</span></button> : null}
        </div>
    );
}
