import ReactDOM from 'react-dom';

import 'utils/registerjquery';
import 'bootstrap';
import 'popper.js/dist/umd/popper.js';
import App from './app';

import { BrowserRouter } from 'react-router-dom';

function render(Component) {
    ReactDOM.render(
        <BrowserRouter>
            <Component />
        </BrowserRouter>,
        document.querySelector('.app')
    );
}

if (document.querySelector('.app')) {
    render(App);

    if (module.hot) {
        module.hot.accept('./app', () => {
            render(App);
        });
    }
}
