import Loader from 'components/loader';
import { useEntry, useTitle } from 'hooks';
import { useParams } from 'react-router';
import PlayerTemplate from '../components/player';
import NotFound from './not_found';

export default function Player() {
    const { entryId } = useParams();
    const { item, loading } = useEntry(entryId);

    const title = item ? item.metaData.title : '';

    useTitle(title);

    return (
        <Loader loading={loading}>
            {item ? <PlayerTemplate item={item} /> : null}
            {!item && !loading ? <NotFound /> : null}
        </Loader>
    );
}
