import { useCallback } from 'react';

export default function LiveLogin() {
    const startLogin = useCallback(() => {
        document.querySelector('#login-button').click();
    }, []);

    return (
        <div className="wf-section">
            <h5 className="heading">Log in to your Account</h5>
            <p className="paragraph-2">Once logged into your account you will gain access to purchase and use live stream subscriptions.</p>
            <div className="w-row">
                <div className="columns-center">
                    <button className="button-4 w-button" onClick={startLogin}>Log In / Create Account</button>
                </div>
            </div>
        </div>
    );
}
