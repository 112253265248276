import { useCallback, useState } from 'react';
import VoucherForm from './voucher_form';
import VoucherError from './voucher_error';
import PlayerAPI from 'API/player';

export default function VoucherRedeem() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const onFormSubmit = useCallback(code => {
        setLoading(true);
        return new Promise(resolve => {
            window.streamPayments.isVoucherCodeValid(
                { code: code },
                result => {
                    resolve(result);
                }
            );
        })
            .then(response => {
                setLoading(false);
                if (!response.IsValid) {
                    setError(response.ModelErrors.code);
                    return;
                }
                PlayerAPI.clearCache();
                window.location.href = response.RedirectUrl;
            })
            .catch(caughtError => {
                console.error(caughtError);
                setLoading(false);
                setError('Unable to redeem code. Please try again later.');
            });
    }, []);

    return (
        <VoucherForm onFormSubmit={onFormSubmit} loading={loading}>
            {error ? <VoucherError message={error} /> : null}
        </VoucherForm>
    );
}
