import { useCallback, useEffect, useState } from 'react';
import PlayerAPI from '../API/player';

/**
 * React hook for payments state
 *
 * @return {{session: object, messages: object[], entitlements: string[], packages: string[]}|null}
 */
 export function usePayments() {
    const [loginState, setLoginState] = useState(null);

    useEffect(() => {
        const subscription = PlayerAPI.subscribeState(state => {
            if (state) {
                let entitlements = [],
                    packages = [];
                if (state.data && state.data.CurrentCustomerSessionStatus === -1 && state.data.CurrentCustomerSession) {
                    if (state.data.CurrentCustomerSession.CustomerEntitlements) {
                        entitlements = state.data.CurrentCustomerSession.CustomerEntitlements.split(',');
                    }
                    if (state.data.CurrentCustomerSession.CustomerPackages) {
                        packages = state.data.CurrentCustomerSession.CustomerPackages.split(',');
                    }
                }

                setLoginState({
                    loggedIn: state.data && state.data.CurrentCustomerSessionStatus === -1,
                    profileUrl: state.profileUrl,
                    session: state.data,
                    messages: state.messages,
                    entitlements,
                    packages,
                });
            } else {
                setLoginState({
                    loggedIn: false,
                    profileUrl: false,
                    session: false,
                    messages: [],
                    entitlements: [],
                    packages: [],
                });
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return loginState;
}

/**
 * Get packages by packageIds, or get all packages if packageIds not specified
 * If packageIds is specified and exclude is true, it is inverted and returns all packages excluding those given
 *
 * @param {Array<String>} packageIds
 * @param {Boolean} exclude
 * @param {string} legacyFilter Only valid if no packageIds specified, and it will filter by package Style
 * @returns {[packages: Array<Object>|null, loading: Boolean]}
 */
export function usePaymentsPackages(packageIds, exclude, legacyFilter) {
    const [packages, setPackages] = useState(null);

    useEffect(() => {
        let mounted = true;
        // TODO: Update player API to payments API with RxJS
        PlayerAPI.getSubscriptionPackageList().then(newPackages => {
            if (!mounted) {
                return;
            }
            setPackages(newPackages);
        });
        return () => {
            mounted = false;
        };
    }, []);

    if (!packages) {
        return [[], true];
    }

    if (!packageIds) {
        // TODO: This can be removed once /video/packages page is gone and replaced by content page
        if (legacyFilter === 'audio') {
            return [packages.filter(packagesEntry => packagesEntry.Style === 'audio'), false];
        } else if (legacyFilter === 'video') {
            return [packages.filter(packagesEntry => packagesEntry.Style !== 'audio'), false];
        }
        return [packages, false];
    }

    if (exclude) {
        return [packages.filter(packagesEntry => !packageIds.includes(packagesEntry.Id)), false];
    }

    // Maintain the order specified in the CMS
    return [packageIds.map(packageId => packages.find(packageEntry => packageEntry.Id === packageId)).filter(packageEntry => packageEntry), false];
}

/**
 * Redeem voucher callback
 *
 * @param {(message: import('./messages').Message|import('./messages').Message[]) => void} addMessages
 * @returns {[redeemVoucher: (voucherCode: string) => void, loading: Boolean]}
 */
export function usePaymentsVoucherCallback(addMessages) {
    const [voucherLoading, setVoucherLoading] = useState(false);

    const redeemVoucher = useCallback(voucherCode => {
        if (voucherLoading) {
            return;
        }

        setVoucherLoading(true);

        PlayerAPI.getVoucherUrl(voucherCode).subscribe({
            next: redirectUrl => {
                PlayerAPI.clearCache();
                window.location.href = redirectUrl;
            },
            error: error => {
                setVoucherLoading(false);
                addMessages({
                    key: 'voucher_code',
                    type: 'alert',
                    title: 'Invalid Voucher Code',
                    message: error
                });
            }
        });
    }, [addMessages, voucherLoading]);

    return [redeemVoucher, voucherLoading];
}
