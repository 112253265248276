import { Link } from 'react-router-dom';
import MediaPlayer from './media_player';

export default function Player(props) {
    const { item } = props;
    const { mediaData, metaData } = item;
    const { entryId } = mediaData;
    const { title } = metaData;

    return (
        <>
            <div className="section-6 wf-section">
                <div className="video-2 w-video w-embed"><MediaPlayer entryId={entryId} /></div>
                <h1 class="heading-3">{title}</h1>
            </div>
            <div className="section-5 wf-section">
                <h1 className="heading-4">WATCH ON-DEMAND VIDEO CONTENT WITH THE CLUB'S ONLINE TV STATION - MARINERS TV.</h1>
                <p className="paragraph-6">Also, don't miss a second of Town&#x27;s matches throughout the 2022/23 season - to
                    follow all the live action see our list of subscription passes below.</p>
                <div className="columns-center">
                    <Link to="/packages" className="button-4 w-button">Subscription Passes</Link>
                </div>
            </div>
        </>
    );
}
