
export default function PackageItem(props) {
    const { packageItem, owned, onPackageClick } = props;
    const { Title, Body, AmountFormatted, PurchaseUrl } = packageItem;

    return (
        <div className="w-col w-col-6">
            <h3 className="heading-5">{Title} - {AmountFormatted}</h3>
            <p className="paragraph-7" dangerouslySetInnerHTML={{ __html: Body }}></p>
            {owned ? (
                <p className="paragraph-7"><em>You own this package</em></p>
            ) : (
                <a href={PurchaseUrl} onClick={onPackageClick} className="button-4 w-button">Buy Now</a>
            )}
        </div>
    );
}
