import React, { useCallback, useContext, useMemo, useState } from 'react';

const LoadingContext = React.createContext();

export function useLoaderContext() {
    const context = useContext(LoadingContext);
    return [context.addItem, context.removeItem];
}

export function LoaderProvider(props) {
    const { initial } = props;
    const [tally, setTally] = useState(initial ? [''] : []);

    const addItem = useCallback(item => {
        setTally(previous => previous.filter(entry => entry).concat([item]));
    }, []);

    const removeItem = useCallback(item => {
        setTally(previous => previous.filter(entry => entry).filter(entry => entry !== item));
    }, []);

    const providerValue = useMemo(() => {
        return { addItem, removeItem };
    }, [addItem, removeItem]);

    let loaderProps = { ...props };
    if (tally.length > 0) {
        loaderProps.loading = true;
    }

    return (
        <LoadingContext.Provider value={providerValue}>
            <Loader {...loaderProps} />
        </LoadingContext.Provider>
    );
}

/**
 * Loader component.
 *
 * Set loading to true to "cloak" all content inside the component.
 * Set noMinHeight to true if you want the loader to always be the same size as the content. Usually not something you want to do if the children could be empty and collapse.
 *
 * Add your child components that are affected by the loading state as children of this component
 *
 * @param {{
 *   className: string,
 *   noMinHeight: boolean,
 *   loading: boolean,
 * }} props
 */
export default function Loader(props) {
    const { className, loading, noMinHeight, children } = props;

    let parentClassName = 'o-loader';
    if (className) {
        parentClassName += ` ${className}`;
    }
    if (loading) {
        parentClassName += ' o-loader--active';
    }
    if (noMinHeight) {
        parentClassName += ' o-loader--no-min';
    }

    return (
        <div className={parentClassName}>
            {children}
            <div className="o-loader__curtain">
                <div className="o-loader__icon"></div>
            </div>
        </div>
    );
}
