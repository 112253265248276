import Loader from 'components/loader';
import { useTitle } from 'hooks';
import { useFeed } from 'hooks/cloudmatrix';
import HomeTemplate from '../components/home';
import NotFound from './not_found';

export default function Home() {
    const { feed, loading } = useFeed('0a7a1244-cdb0-4c80-ae34-84378473a3ae');

    useTitle('');

    return (
        <Loader loading={loading}>
            {feed ? <HomeTemplate feed={feed}></HomeTemplate> : null}
            {!feed && !loading ? <NotFound /> : null}
        </Loader>
    );
}
