import { thumbnailForSize } from 'utils/thumbnail';

export default function SectionItem(props) {
    const { item } = props;
    const { mediaData, metaData } = item;
    const { entryId, thumbnailUrl } = mediaData;
    const { title, categories } = metaData;

    const url = `/player/${entryId}`;

    return (
        <div className="w-col w-col-3">
            <div className="video w-video w-embed">
                <a href={url}><img src={thumbnailForSize(thumbnailUrl, 1024, 576)} /></a>
            </div>
            {categories.length ? <p className="paragraph-5">{categories[0]}</p> : null}
            <p className="paragraph-8"><a className="plain-link" href={url}><strong className="bold-text">{title}</strong></a></p>
        </div>
    );
}
