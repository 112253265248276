import StreamSelectorItem from './stream_selector_item';

export default function StreamSelector(props) {
    const { availableStreams, setCurrentStream } = props;

    return (
        <div className="columns-center">
            {availableStreams.map(item => <StreamSelectorItem key={item.id} setCurrentStream={setCurrentStream} item={item} />)}
        </div>
    );
}
