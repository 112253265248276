import Loader from 'components/loader';
import { useTitle } from 'hooks';
import { useFeed } from 'hooks/cloudmatrix';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import SectionTemplate from '../components/section';
import NotFound from './not_found';

export default function Section() {
    const { feedId, sectionId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const currentPage = useMemo(() => {
        return Number(searchParams.get('page')) || 0;
    }, [searchParams]);

    const { feed, loading } = useFeed(feedId, sectionId, currentPage);

    useTitle(feed && feed.section ? feed.section.name : 'Not Found');

    const setCurrentPage = useCallback(page => {
        setSearchParams({ page });
    }, [setSearchParams]);

    return (
        <Loader loading={loading}>
            {feed && feed.section ? <SectionTemplate section={feed.section} currentPage={currentPage} setCurrentPage={setCurrentPage} /> : null}
            {!feed && !loading ? <NotFound /> : null}
        </Loader>
    );
}
