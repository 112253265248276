/* eslint no-process-env: off, no-console: off */

import { Observable } from 'rxjs';

/**
 * Load cached script
 *
 * @param {String} url
 * @return {Observable<boolean>}
 */
export default function loadCachedScript(url) {
    return new Observable(observer => {
        let document = window.document,
            head = document.head,
            element = document.createElement('script');
        element.onload = () => {
            if (process.env.NODE_ENV === 'development') {
                console.log('[LoadCachedScript] Completed', url);
            }
            observer.next(true);
            observer.complete();
        };
        element.onerror = error => {
            if (process.env.NODE_ENV === 'development') {
                console.error('[LoadCachedScript] Error', url, error);
            }
            observer.error(error);
        };
        element.type = 'text/javascript';
        element.src = url;

        if (process.env.NODE_ENV === 'development') {
            console.log('[LoadCachedScript] Starting', url);
        }

        // Insert it at the end of the head in a legacy-friendly manner
        head.insertBefore(element, head.childNodes[head.childNodes.length - 1].nextSibling);
    });
}
