
export default function VoucherError(props) {
    const { message } = props;

    return (
        <div className="callout alert">
            <strong>{message}</strong>
        </div>
    );
}
