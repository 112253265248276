import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

export default function LinkHandler() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [location]);

    useEffect(() => {
        function clickHandler(event) {
            // Ignore things that might open in new tab
            if (
                event.ctrlKey ||
                event.shiftKey ||
                event.metaKey ||
                (event.button && event.button == 1)
            ) {
                return;
            }

            let element = event.target;
            while (element && element.nodeName !== 'A') {
                element = element.parentNode;
            }
            if (!element || !element.getAttribute('href') || !element.hostname || !element.pathname) {
                return;
            }

            if (element.hostname.replace(new RegExp('^www\\.'), '') !== window.location.hostname.replace(new RegExp('^www\\.'), '')) {
                return;
            }

            event.preventDefault();
            navigate(`${element.pathname}${element.search}`);
        }

        // Attach to all link clicks
        document.addEventListener('click', clickHandler);
        return () => {
            document.removeEventListener('click', clickHandler);
        };
    });

    // Yes, this is kind of a hook, but we need it in the top level boundary component where routes are rendered
    // TODO: Don't render routes in the boundary class - move them to own component
    return null;
}
