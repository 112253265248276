
export default function LiveOffline() {
    return (
        <div className="wf-section">
            <img src="/img/offline.png"
                srcset="/img/offline-500.png 500w, /img/offline-800.png 800w, /img/offline.png 877w"
                sizes="(max-width: 479px) 83vw, (max-width: 767px) 90vw, (max-width: 991px) 92vw, 99vw" alt="Offline. Come back later for our next live stream."
                className="image" />
        </div>
    );
}
