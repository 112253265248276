import { useCallback } from 'react';

export default function StreamSelectorItem(props) {
    const { setCurrentStream, item } = props;
    const { metaData } = item;
    const { title } = metaData;

    const onClick = useCallback(() => {
        setCurrentStream(item);
    }, [setCurrentStream, item]);

    return (
        <button onClick={onClick} className="button w-button">{title}</button>
    );
}
