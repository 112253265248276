import { Link } from 'react-router-dom';

export default function Live(props) {
    const { children } = props;

    return (
        <>
            {children}
            <div className="section-7">
                <p className="paragraph">For any technical queries, please email <a href="mailto:marinerstv@streamamg.com">marinerstv@streamamg.com</a>.<br />
                    For queries and cancellations, please email <a href="mailto:marinerstv@gtfc.co.uk">marinerstv@gtfc.co.uk</a>.<br />
                    <Link to="/terms">Read our Mariners TV Terms and Conditions.</Link><br />
                    <Link to="/faq">Read our Mariners TV FAQs.</Link></p>
            </div>
            <div className="section-7 wf-section">
                <h3 className="heading">WATCH ON-DEMAND VIDEO CONTENT WITH THE CLUB'S ONLINE TV STATION - MARINERS TV.</h3>
                <p className="paragraph-2">Also, don't miss a second of Town&#x27;s matches throughout the 2022/23 season - to
                    follow<br />all the live action see our list of subscription passes below.</p>
                <div className="columns-center">
                    <Link to="/packages" className="button-4 w-button">Subscription Passes</Link>
                </div>
            </div>
        </>
    );
}
