import Loader from 'components/loader';
import { usePayments, useTitle } from 'hooks';
import { useFeed } from 'hooks/cloudmatrix';
import LiveOffline from '../components/live_offline';
import LiveMissing from '../components/live_missing';
import LiveSubscribe from '../components/live_subscribe';
import LiveLogin from '../components/live_login';
import LivePlayer from '../components/live_player';
import LiveNoMobile from '../components/live_no_mobile';
import LiveLoading from '../components/live_loading';
import LiveTemplate from '../components/live';
import { useEffect, useState } from 'react';

export default function Live() {
    const { feed, loading: feedLoading } = useFeed('6e5e5838-66fa-4ecf-85f5-19fef6a2ed59', null, null, 30000);
    const loginState = usePayments();

    useTitle('Live');

    const [loading, setLoading] = useState(true);
    const [hasFreeStream, setHasFreeStream] = useState(false);
    const [hasFreeWithLoginStream, setHasFreeWithLoginStream] = useState(false);
    const [liveEntitlements, setLiveEntitlements] = useState([]);
    const [availableStreams, setAvailableStreams] = useState([]);
    const [currentStream, setCurrentStream] = useState(null);
    const [isMobile, setIsMobile] = useState(() => window.innerWidth < 415);
    const isLoading = loading || feedLoading || !loginState;
    const isLoggedIn = loginState && loginState.session && loginState.session.CurrentCustomerSessionStatus === -1;
    const hasSubscription = isLoggedIn && loginState.entitlements.length != 0;
    const isSubscribed = isLoggedIn && liveEntitlements.findIndex(entitlement => loginState.entitlements.includes(entitlement)) != -1;

    useEffect(() => {
        function updateWindowWidth() {
            setIsMobile(window.innerWidth < 415);
        }
        window.addEventListener('resize', updateWindowWidth);
        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        };
    }, []);

    useEffect(() => {
        if (!loginState || !feed) {
            return;
        }

        const { sections } = feed;
        const liveEntries = sections.reduce((previousValue, currentValue) => {
            return previousValue.concat(currentValue.itemData);
        }, []);

        let newHasFreeStream = false,
            newHasFreeWithLoginStream = false,
            newLiveEntitlements = [],
            newAvailableStreams = [],
            bestStream = null;

        // Reverse so that best stream becomes the FIRST entry available and live
        for (let entry of liveEntries.slice(0).reverse()) {
            const entryEntitlements = entry.metaData.SysEntryEntitlements;
            if (entryEntitlements.length == 0) {
                // No entitlements set - cannot use at all - don't even show live
                continue;
            }

            entry.access = true;
            if (!loginState.session || loginState.session.CurrentCustomerSessionStatus !== -1) {
                if (entryEntitlements.includes('?')) {
                    // Free without login
                    newHasFreeStream = true;
                } else if (entryEntitlements.includes('*')) {
                    // Free with login, not logged in
                    newHasFreeWithLoginStream = true;
                } else {
                    // Needs login, this will be handled in the display below
                }
            } else if (entryEntitlements.includes('?')) {
                // Free without login
                newHasFreeStream = true;
            } else if (entryEntitlements.includes('*')) {
                // Free with login
                newHasFreeWithLoginStream = true;
            } else if (loginState.entitlements.length != 0 && entryEntitlements.find(entitlement => loginState.entitlements.includes(entitlement))) {
                // Subscription present
            } else {
                // No entitlement, cannot use, let video display the payments response saying so
                entry.access = false;
            }

            // Customer has entitlement! Allow it on the list of available streams
            newLiveEntitlements.push(...entryEntitlements);
            newAvailableStreams.unshift(entry);

            // First live stream (we're iterating in reverse, so here it is last) is the best stream for the user (the default stream)
            // Set as best stream if we don't have one
            // If we do have one, and it's not accessible, just take over it
            // If we have a bestStream and it is accessible, only override if we find a better accessible
            if (!bestStream || !bestStream.access || entry.access) {
                bestStream = entry;
            }
        }

        setHasFreeStream(newHasFreeStream);
        setHasFreeWithLoginStream(newHasFreeWithLoginStream);
        setLiveEntitlements(newLiveEntitlements);
        setAvailableStreams(newAvailableStreams);
        setCurrentStream(existing => {
            if (existing && newAvailableStreams.find(stream => stream.mediaData.entryId === existing.mediaData.entryId)) {
                return existing;
            }
            return bestStream;
        });
        setLoading(false);
    }, [loginState, feed]);

    let Component;
    if (isLoading) {
        Component = <LiveLoading />;
    } else if (availableStreams.length != 0 && (hasFreeStream || (isLoggedIn && hasFreeWithLoginStream) || isSubscribed)) {
        if (!window.tvConfig.allowMobileWeb && isMobile) {
            Component = <LiveNoMobile />;
        } else {
            Component = <LivePlayer availableStreams={availableStreams} currentStream={currentStream} setCurrentStream={setCurrentStream} />;
        }
    } else if (!isLoggedIn) {
        Component = <LiveLogin />;
    } else if (availableStreams.length != 0 && !hasSubscription) {
        Component = <LiveSubscribe />;
    } else if (availableStreams.length != 0) {
        Component = <LiveMissing />;
    } else {
        Component = <LiveOffline />;
    }

    return (
        <Loader loading={isLoading}>
            <LiveTemplate>
                {Component}
            </LiveTemplate>
        </Loader>
    );
}
