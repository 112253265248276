import { Component } from 'react';

import Home from './pages/home';
import Live from './pages/live';
import Packages from './pages/packages';
import Section from './pages/section';
import Player from './pages/player';
import NotFound from './pages/not_found';
import { Route, Routes } from 'react-router';
import LinkHandler from 'components/link_handler';
import LoginHandler from 'components/login_handler';
import Terms from 'pages/terms';
import FAQ from 'pages/faq';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            error_info: null
        };
    }

    componentDidCatch(error, error_info) {
        this.setState({ error, error_info });
    }

    resetError = () => {
        this.setState({
            error: null,
            error_info: null
        });
    };

    render() {
        if (this.state.error) {
            return (
                <div className="c-error-boundary">
                    <h1>Something went wrong!</h1>
                    <p><a onClick={this.resetError}>Try again</a></p>
                </div>
            );
        }

        return (
            <>
                <LinkHandler />
                <LoginHandler />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/live" element={<Live />} />
                    <Route path="/packages" element={<Packages />} />
                    <Route path="/section/:feedId/:sectionId" element={<Section />} />
                    <Route path="/player/:entryId" element={<Player />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route element={<NotFound />} />
                </Routes>
            </>
        );
    }
}

export default App;
