import HomeSectionItem from './home_section_item';

export default function HomeSection(props) {
    const { feedId, section } = props;
    const { id, name, itemData } = section;

    return (
        <div className="wf-section">
            <div className="w-row">
                <div className="w-col w-col-3">
                    <h2 className="heading-2">{name}</h2>
                </div>
            </div>
            <div className="w-row">
                {itemData.slice(0, 4).map(item => <HomeSectionItem key={item.id} item={item} />)}
            </div>
            <div className="columns-center">
                <a href={`/section/${feedId}/${id}`} className="button-4 w-button">View All</a>
            </div>
        </div>
    );
}
