// Do not enter anymore corejs polyfills here - see .babelrc - they are handled by @babel/preset-env
// HOWEVER, @babel/preset-env does not recognise Webpack's requirements for dynamic imports
// so we need to specify Promise and Iterator manually (see https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import)
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
// Furthermore, we need to polyfill Symbol.observable because rxjs polyfills it in an uncomplete way, resulting in "@@observable" for Safari instead of Symbol(observable)
// Because redux polyfills it correctly it then causes the two to become incompatible as rxjs begins to think that Redux's Store object is not implementing Obsevable
import 'core-js/modules/esnext.symbol.observable';
// Same issue occurs with Symbol with React between React-DOM and React - they disagree on the definition in IE11
// Can be removed if we stop supporting IE11
import 'core-js/modules/es.symbol';
// This order is important - we import object-fit-images FIRST, then picturefill (cause it will then polyfill automatically), then we call objectFitImages().
// When we are updating things we just need to call picturefill first again and then object-fit-images
if (window.objectFitImages) {
    window.objectFitImages();
}
