import { Component } from 'react';
import jQuery from 'jquery';

class Messages extends Component {
    removeMessage = event => {
        let key = jQuery(event.target)
            .closest('.close-button')
            .data('key');
        if (typeof key !== 'undefined') {
            this.props.removeMessage(key);
        }
    };

    render() {
        if (!this.props.messages.length) {
            return null;
        }

        return (
            <div className="c-messages">
                {this.props.messages.map(message => {
                    return (
                        <div key={message.key} role="alert" data-closable className={'m-callout callout alert-callout-border ' + (message.type || 'alert')}>
                            <span className="m-callout__title font-bold">{message.title}</span> - {message.message}{' '}
                            {message.linkUrl ? (
                                <a href={message.linkUrl} className="m-callout__link font-bold">{message.linkText || message.linkUrl}</a>
                            ) : null}
                            {message.persistent ? null : (
                                <button className="m-callout__close close-button" aria-label="Dismiss message" type="button" data-close data-key={message.key} onClick={this.removeMessage}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default Messages;
