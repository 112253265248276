import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
import PlayerAPI from 'API/player';
import Loader from './loader';
import { usePayments } from 'hooks';
import { Link } from 'react-router-dom';

export default function LoginForm(props) {
    const { onClose } = props;
    const [container, setContainer] = useState(null);
    const loginState = usePayments();
    const [loading, setLoading] = useState(false);
    const [didSubmit, setDidSubmit] = useState(false);
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const newContainer = document.createElement('div');
        newContainer.classList.add('reveal');

        const reveal = new Reveal(jQuery(newContainer));
        reveal.$element.on('open.zf.reveal', () => {
            newContainer.querySelector('[name=emailaddress]')?.focus();
        });
        reveal.$element.on('closed.zf.reveal', () => {
            if (onClose) {
                onClose();
            }
        });

        newContainer.$reveal = reveal;

        setContainer(newContainer);

        return () => {
            reveal._destroy();
            newContainer.remove();
        };
    }, [onClose]);

    useEffect(() => {
        if (container) {
            container.$reveal.open();
        }
    }, [container]);

    // Stop loading after a change in login state
    // But if we successfully logged in, don't, so we allow cascade to close the form
    useEffect(() => {
        setDidSubmit(true);

        if (loginState && loginState.session.ModelErrors) {
            setLoading(false);
        }
    }, [loginState]);

    const doLogin = useCallback(event => {
        event.preventDefault();

        if (!password) {
            setError('Please enter a password.');
            return;
        }

        setDidSubmit(false);
        setLoading(true);
        setError('');
        PlayerAPI.isEmailAddressValid(email, valid => {
            if (valid) {
                PlayerAPI.doLogin(email, password);
            } else {
                setError('Please enter a valid email address.');
                setLoading(false);
            }
        });
    }, [email, password]);

    const updateEmail = useCallback(event => {
        setEmail(event.target.value);
    }, []);
    const updatePassword = useCallback(event => {
        setPassword(event.target.value);
    }, []);
    const closeReveal = useCallback(() => {
        if (container) {
            container.$reveal.close();
        }
    }, [container]);

    if (!container || loginState.loggedIn) {
        return null;
    }

    return ReactDOM.createPortal(
        <>
            <button className="login-close" onClick={closeReveal}><span aria-hidden="true">X</span><span className="show-for-sr">Close</span></button>
            <Loader loading={loading}>
                <form onSubmit={doLogin}>
                    <div className="wf-section">
                        <h3 className="heading">Login to live streaming</h3>
                        <p className="paragraph-2">Don't have an account yet?<br />
                        <Link to="/packages" onClick={closeReveal}>Choose a package to begin your journey.</Link></p>
                        {error ? (
                            <div className="callout alert">
                                <strong>Error: </strong> {error}
                            </div>
                        ) : null}
                        {!error && didSubmit && loginState.session && Object.keys(loginState.session.ModelErrors).length > 0 ? (
                            <div className="callout alert">
                                {Object.keys(loginState.session.ModelErrors).map(field => {
                                    return (
                                        <p key={field}>
                                            {field === 'emailaddress' ? <strong>Email Address: </strong> : null}
                                            {field === 'password' ? <strong>Password: </strong> : null}
                                            {loginState.session.ModelErrors[field]}
                                        </p>
                                    );
                                })}
                            </div>
                        ) : null}
                        <p>
                            <label>Email Address
                                <input type="text" placeholder="" name="emailaddress" value={email} onChange={updateEmail} />
                            </label>
                        </p>
                        <p>
                            <label>Password
                                <input type="password" placeholder="" name="password" value={password} onChange={updatePassword} />
                            </label>
                            <p><a href={window.tvConfig.resetPasswordEndpoint}>Forgotten your password?</a></p>
                        </p>
                        <div className="columns-center">
                            <button type="submit" className="button-4 w-button">Login</button>
                        </div>
                    </div>
                </form>
            </Loader>
        </>,
        container
    );
}
