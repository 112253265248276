import { Link } from 'react-router-dom';
import { thumbnailForSize } from 'utils/thumbnail';
import HighlightsItem from './highlights_item';

export default function Highlights(props) {
    const { feedId, section } = props;
    const { id, name, itemData } = section;

    const firstItem = itemData[0];
    const { mediaData, metaData } = firstItem;
    const { entryId, thumbnailUrl } = mediaData;
    const { title } = metaData;

    const url = `/player/${entryId}`;

    return (
        <>
            <div className="columns w-row">
                <div className="w-col w-col-7">
                    <h1 className="heading-2">{name}</h1>
                </div>
                <div className="w-col w-col-5">
                    <Link to="/live" className="button-3 w-button">Watch Live Stream</Link>
                </div>
            </div>
            <div className="w-row">
                <div className="w-col w-col-7">
                    <div className="video-2 w-video w-embed">
                        <a href={url}><img src={thumbnailForSize(thumbnailUrl, 1024, 576)} /></a>
                    </div>
                    <h1 className="heading-3"><a className="plain-link-black" href={url}>{title}</a></h1>
                </div>
                {itemData.length > 1 ? (
                    <div className="w-col w-col-5">
                        {itemData.slice(1, 4)
                            .map(item => <HighlightsItem key={item.id} item={item} />)
                            .reduce((previousValue, currentValue, currentIndex) => {
                                if (!previousValue.length) {
                                    // Add without separator
                                    previousValue.push(currentValue);
                                    return previousValue;
                                }
                                // Add 2nd and onwards with a separator
                                previousValue.push(<div key={`separator-${currentIndex}`} className="section-2 wf-section"></div>);
                                previousValue.push(currentValue);
                                return previousValue;
                            }, [])
                        }
                    </div>
                ) : null}
            </div>
            <div className="columns-center">
                <a href={`/section/${feedId}/${id}`} className="button-4 w-button">View All</a>
            </div>
        </>
    );
}
