import { useCallback, useState } from 'react';

export default function VoucherForm(props) {
    const { loading, children, onFormSubmit } = props;
    const [voucherCode, setVoucherCode] = useState('');

    const updateVoucherCode = useCallback(event => {
        setVoucherCode(event.target.value);
    }, []);

    const onVoucherSubmit = useCallback(event => {
        event.preventDefault();
        onFormSubmit(voucherCode);
    }, [onFormSubmit, voucherCode]);

    return (
        <form onSubmit={onVoucherSubmit}>
            <div className="wf-section">
                <div className="columns-4 w-row columns-center">
                    <div className="w-col w-col-6">
                        {children}
                        <label htmlFor="redeemVoucher">Enter voucher code to redeem
                            <input type="text" id="redeemVoucher" value={voucherCode} onChange={updateVoucherCode} /></label>
                        <div className="columns-center">
                            <button type="submit" className="button-4 w-button" disabled={loading}>Redeem</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
