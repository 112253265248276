import { Link } from 'react-router-dom';
import Messages from './messages';
import PackageList from './package_list';
import VoucherRedeem from './voucher_redeem';

export default function Packages(props) {
    const { profileUrl, videoPackages, audioPackages, customerPackages, messages, removeMessages, onPackageClick } = props;

    return (
        <>
            <Messages messages={messages} removeMessage={removeMessages} />
            <div className="section-8 wf-section">
                <h1 className="heading-2">STREAMING PASSES</h1>
                <p className="paragraph-7">Thanks for popping by, any Mariners TV Matchday packages will be available to purchase
                    here.
                </p>
                {profileUrl ? (
                    <div className="columns-center">
                        <a href={profileUrl} className="button-4 w-button">Manage Your Subscriptions</a>
                    </div>
                ) : null}
            </div>
            {videoPackages ? <PackageList packages={videoPackages} customerPackages={customerPackages} onPackageClick={onPackageClick} /> : null}
            {audioPackages ? <PackageList packages={audioPackages} customerPackages={customerPackages} onPackageClick={onPackageClick} /> : null}
            {window.tvConfig.enableVoucher ? <VoucherRedeem /> : null}
            <div className="section-8 wf-section">
                <h3 className="heading-5">If you are having any technical issues please contact <a href="mailto:marinerstv@streamamg.com">marinerstv@streamamg.com</a><br /> If
                    you&#x27;ve purchased your package...</h3>
                <div className="columns-3 w-row">
                    <div className="column w-col w-col-6">
                        <Link to="/live" className="button-4 w-button">Click Here to Watch or Listen</Link>
                    </div>
                    <div className="w-col w-col-6"></div>
                </div>
                <h3 className="heading-5">To cancel your subscription please email <a href="mailto:marinerstv@gtfc.co.uk">marinerstv@gtfc.co.uk</a></h3>
            </div>
        </>
    );
}
