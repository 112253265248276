import PackageItem from './package_item';

export default function PackageList(props) {
    const { packages, customerPackages, onPackageClick } = props;

    return (
        <div className="section-10 wf-section">
            <div className="w-row">
                {packages.map(packageItem => <PackageItem key={packageItem.Id} packageItem={packageItem} owned={customerPackages?.includes(packageItem.Id)} onPackageClick={onPackageClick} />)}
            </div>
        </div>
    );
}
