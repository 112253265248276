import Pagination from './pagination';
import SectionItem from './section_item';

export default function Section(props) {
    const { section, currentPage, setCurrentPage } = props;
    const { name, itemData, pagingData } = section;

    return (
        <div className="wf-section">
            <div className="w-row">
                <div className="w-col w-col-3">
                    <h2 className="heading-2">{name}</h2>
                </div>
            </div>
            <div className="columns-wrap">
                {itemData.map(item => <SectionItem key={item.id} item={item} />)}
            </div>
            <Pagination pageCount={pagingData.pageCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
    );
}
