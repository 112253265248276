// Query String parser

function encode(params) {
    let result = [];
    for (let entry of Object.entries(params)) {
        result.push(encodeURIComponent(entry[0]) + '=' + encodeURIComponent(entry[1]));
    }
    return result.join('&');
}

function create(params) {
    if (Object.keys(params).length == 0) {
        return '';
    }

    return '?' + encode(params);
}

function filter(params, callback) {
    return Object.keys(params)
        .filter(callback)
        .reduce((acc, item) => {
            acc[item] = params[item];
            return acc;
        }, {});
}

function parse(querystring) {
    if (!querystring) {
        let hrefSegments = window.location.href.split('?');
        if (!hrefSegments[1]) {
            return false;
        }

        querystring = hrefSegments[1];
    }

    let result = {};
    querystring.split('&').forEach(entry => {
        let keyValue = entry.split('=');
        result[decodeURIComponent(keyValue[0])] = keyValue[1] ? decodeURIComponent(keyValue[1]) : '';
    });

    return result;
}

export default { create, encode, filter, parse };
