import { useEffect, useState } from 'react';
import { ajax } from 'rxjs/ajax';

export function useFeed(feedId, sectionId = null, currentPage = 0, reloadEveryMs = null) {
    const [loading, setLoading] = useState(true);
    const [feed, setFeed] = useState(false);
    const [ticker, setTicker] = useState(0);

    // Only set loading if params change, not if refreshing due to ticker
    useEffect(() => {
        setLoading(true);
    }, [feedId, sectionId, reloadEveryMs, currentPage]);

    useEffect(() => {
        let query = '';
        if (sectionId) {
            query += `?section=${sectionId}&pageSize=24`;
            if (currentPage) {
                query += `&pageIndex=${currentPage}`;
            }
        }

        const subscription = ajax.get(`https://grimsby-cm.streamamg.com/api/v1/a7fb3019-8f36-4a37-83da-316e72888ca3/CIYhY6c9CeMKlAMf5C1Md7sRdMVIuFVLcBNm99Scm7cdVByyBe/dfc92d85-402a-4ffa-873e-b65da6fb476a/en/feed/${feedId}/sections${query}`)
            .subscribe({
                next: response => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('[CloudMatrix] Loaded feed', feedId, response.response);
                    }
                    let newFeed = { ...response.response };
                    if (sectionId) {
                        newFeed.section = newFeed.sections.find(section => section.id === sectionId);
                    }
                    setFeed(newFeed);
                    setLoading(false);
                },
                error: () => {
                    setLoading(false);
                }
            });

        let timeout;
        if (reloadEveryMs) {
            timeout = window.setTimeout(() => {
                setTicker(new Date());
            }, reloadEveryMs);
        }

        return () => {
            subscription.unsubscribe();

            if (timeout) {
                window.clearTimeout(timeout);
            }
        };
    }, [ticker, feedId, sectionId, reloadEveryMs, currentPage]);

    return { loading, feed };
}

export function useEntry(entryId) {
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState(false);

    useEffect(() => {
        setLoading(true);

        const subscription = ajax.get(`https://grimsby-cm.streamamg.com/api/v1/a7fb3019-8f36-4a37-83da-316e72888ca3/CIYhY6c9CeMKlAMf5C1Md7sRdMVIuFVLcBNm99Scm7cdVByyBe/dfc92d85-402a-4ffa-873e-b65da6fb476a/en/search?query=mediaData.entryId:${entryId}&pageSize=1`)
            .subscribe({
                next: response => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('[CloudMatrix] Loaded entry', entryId, response.response);
                    }
                    let newItem = response.response.itemData.find(searchResult => searchResult.mediaData.entryId == entryId);
                    setItem(newItem || false);
                    setLoading(false);
                },
                error: () => {
                    setLoading(false);
                }
            });

        return () => {
            subscription.unsubscribe();
        };
    }, [entryId]);

    return { loading, item };
}
