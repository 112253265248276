import MediaPlayer from './media_player';
import StreamSelector from './stream_selector';

export default function LivePlayer(props) {
    const { availableStreams, currentStream, setCurrentStream } = props;

    return (
        <>
            <div className="section-6 wf-section">
                <div className="w-row">
                    {availableStreams.length > 1 ? <StreamSelector availableStreams={availableStreams} setCurrentStream={setCurrentStream} /> : null}
                </div>
                <div className="w-row">
                    <div className="video-2 w-video w-embed"><MediaPlayer key={currentStream.mediaData.entryId} entryId={currentStream.mediaData.entryId} drm={currentStream.metaData.SysEntryEntitlements.includes('drm')} live="true" /></div>
                    <h1 className="heading-3">{currentStream.metaData.title}</h1>
                </div>
            </div>
        </>
    );
}
