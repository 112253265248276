import { Link } from 'react-router-dom';
import Highlights from './highlights';
import HomeSection from './home_section';

export default function Home(props) {
    const { feed } = props;
    const { feedMetaData } = feed;
    const { id } = feedMetaData;

    if (!feed || !feed.sections.length) {
        return null;
    }

    const firstSection = feed.sections[0];
    const otherSections = feed.sections.slice(1);

    return (
        <>
            {firstSection.itemData.length ? <Highlights feedId={id} section={firstSection} /> : null}
            {otherSections.length ? otherSections.filter(section => section.itemData.length).map(section => <HomeSection key={section.id} feedId={id} section={section} />) : null}
            <div className="section-5 wf-section">
                <h3 className="heading-4">WATCH ON-DEMAND VIDEO CONTENT WITH THE CLUB'S ONLINE TV STATION - MARINERS TV.</h3>
                <p className="paragraph-6">Also, don't miss a second of Town's matches throughout the 2022/23 season - to
                    follow all the live action see our list of subscription passes below.</p>
                <div className="columns-center">
                    <Link to="/packages" className="button-4 w-button">Subscription Passes</Link>
                </div>
            </div>
      </>
    );
}
