import PlayerAPI from 'API/player';
import { usePayments } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import LoginForm from './login_form';

// TODO: Convert this to a provider with a context so we can useLogin()
export default function LoginHandler() {
    const loginState = usePayments();
    const [loading, setLoading] = useState(true);
    const [loginUrl, setLoginUrl] = useState(false);
    const [active, setActive] = useState(false);

    const startLogin = useCallback(() => {
        if (window.tvConfig.authenticationProvider == 'SSO') {
            window.location.href = loginUrl;
        } else {
            setActive(true);
        }
    }, [loginUrl]);
    const endLogin = useCallback(() => {
        setActive(false);
    }, []);
    const doLogout = useCallback(() => {
        PlayerAPI.doLogout();
    }, []);

    useEffect(() => {
        if (window.tvConfig.authenticationProvider == 'SSO') {
            import(/* webpackChunkName: "js/sso" */ 'API/sso').then(SSO => {
                setLoginUrl(SSO.getAuthUrl('Login'));
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (loading || !loginState) {
            return null;
        }

        const element = document.querySelector('#login-button');

        let handler;
        if (loginState.loggedIn) {
            element.innerText = 'Logout';
            handler = doLogout;
            setActive(false); // Close login form after login completes
        } else {
            element.innerText = 'Login';
            handler = startLogin;
        }

        element.addEventListener('click', handler);
        return () => {
            element.removeEventListener('click', handler);
        };
    }, [loading, loginState, doLogout, startLogin]);

    if (!active || !loginState) {
        return null;
    }

    return <LoginForm onClose={endLogin} />;
}
