import PlayerAPI from '../API/player';
import Loader from 'components/loader';
import { useBillingMessages, useMessages, usePayments, usePaymentsPackages, useTitle } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PackagesTemplate from '../components/packages';

export default function Packages() {
    const [searchParams] = useSearchParams();

    const loginState = usePayments();
    const [messages, addMessages, removeMessages, resetBillingMessages] = useMessages();
    useBillingMessages(resetBillingMessages, addMessages, loginState);
    const [videoPackages, videoLoading] = usePaymentsPackages(null, false, 'video');
    const [audioPackages, audioLoading] = usePaymentsPackages(null, false, 'audio');

    const onPackageClick = useCallback(() => {
        PlayerAPI.clearCache();
    }, []);

    useTitle('Packages');

    useEffect(() => {
        if (searchParams.get('purchase') == 'success') {
            PlayerAPI.clearCache();
        }
    }, [searchParams]);

    return (
        <Loader loading={!loginState || videoLoading || audioLoading}>
            <PackagesTemplate videoPackages={videoPackages} audioPackages={audioPackages} customerPackages={loginState?.packages} messages={messages} removeMessages={removeMessages} onPackageClick={onPackageClick} />
        </Loader>
    );
}
