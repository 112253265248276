import { useTitle } from 'hooks';

export default function FAQ() {
    useTitle('FAQ');

    return (
        <div className="section-8 wf-section">
            <h1 className="heading-2">MARINERS TV FREQUENTLY ASKED QUESTIONS</h1>
            <p className="paragraph-7"><strong>What is Mariners TV?<br />
            </strong>Mariners TV is the club&#x27;s live streaming service that provides audio and video packages for the club&#x27;s fixtures.<br />
            <br />
            <strong>What is Mariners TV and what does it offer?</strong><br />
            Live Audio: Mariners TV offers fans alternative ways to follow matches if they can&#x27;t watch games with live commentary of every game during the season.<br />
            Live Video: Live video coverage of games is also available for all domestic and international fans, but there are restrictions on what fixtures can be broadcast due to EFL broadcasting rules and regulations.<br />
            <br />
                <strong>How much does a Mariners TV subscription cost?</strong><br />
            The following is a breakdown of the subscription packages and their costs:<br />
            <br />
                SEASON PASS (INTERNATIONAL) - £140 (£170 from a date TBC in August)<br />
                This package is available to supporters who live outside of the UK and includes live streams to every eligible League Two and Football League Trophy game during the season. International Season Pass subscribers also receive audio commentary of every competitive game and access to the exclusive video content.<br />
            <br />
                MONTHLY PASS (INTERNATIONAL) - £25<br />
                International Monthly Pass subscribers receive the live streams to every eligible League Two and Football League Trophy game during the month, audio commentary of every competitive game and access to the exclusive video content.<br />
            <br />
                AUDIO SEASON PASS (UK) - £45<br />
                A Mariners TV Season Pass offers UK fans a great way to follow the Mariners throughout the 2023/24 campaign, with audio commentary of every competitive game as well as access to exclusive video content. This does not include live streaming of eligible fixtures.<br />
            <br />
                AUDIO MONTHLY PASS (UK)<br />
                A Monthly Pass provides subscribers who live in the UK with the audio commentary of every competitive fixture taking place within the month, plus access to the video content on Mariners TV. This does not include live streaming of eligible fixtures.<br />
            <br />
                MATCH PASSES - £10<br />
                Through Mariners TV, supporters will be able to live stream all eligible League Two and Football League Trophy fixtures on a game-by-game basis for £10. For international fans, that's every League Two and Papa John's game (excluding play-off games or the semi-final and final of the Trophy). UK-based supporters, however, will only be able to stream games which meet these criteria:<br />
                - Scheduled midweek matches<br />
                - Displaced Saturday matches (fixtures rearranged for midweek or during eligible match days)<br />
                - Bank Holiday matches<br />
                - International Break matches with a 3pm kick-off<br />
                - Football League Trophy matches (except semi-finals and finals)<br />
                - Final Round of Fixtures<br />
            <br />
                <strong>Will my seasonal or monthly subscription automatically renew?</strong><br />
            Seasonal subscriptions will not automatically renew and will only be valid for the season in which they are bought. Monthly audio/video packages will automatically renew unless cancelled. Individual game packages will expire after the purchased game - they are one-off payments.<br />
            <br />
                <strong>What devices does Mariners TV work on?<br />
            </strong>Live audio commentary can be accessed across desktop, tablet and mobile devices with up-to-date browsers. Live video streams are now available on the desktop and mobile versions of our live streaming website marinerstv.co.uk<br />
            <br />
                If you are unsure whether your technology will support Mariners TV, please contact marinerstv@streamamg.com with your device and browser details.<br />
            <br />
                <strong>What are the system requirements?<br />
            </strong>For use of Mariners TV your device needs to comply with the following minimum system requirements:<br />
            <br />
            Processor: 2.33GHz or faster x86-compatible processor, or Intel® Atom™ 1.6GHz or faster processor for netbooks;<br />
            <br />
            Memory: 2GB (1GB for netbooks) 128MB of graphics memory.<br />
            <br />
            Operating Systems: Windows 8.1 or later, Mac 9+<br />
            <br />
            Browsers: latest versions of Microsoft Edge, Mozilla Firefox and Google Chrome, Safari; 512MB of RAM (1GB of RAM recommended for netbooks). Google Chrome recommended where possible.<br />
            <br />
            Mobiles &amp; Tablets: Operating System: Android 6+, iOS 9+<br />
            <br />
            <strong>What are the internet connection requirements?</strong><br />
            You will require a WIFI or broadband internet connection (DSL/cable or higher) with a minimum download speed of at least 1.2mbps with latency not exceeding 100ms for SD and 4mbps with latency not exceeding 50ms for HD.<br />
            <br />
            <strong>I&#x27;m having issues with my video or audio feed. What can I do?<br />
            </strong>In the first instance please report your technical issues to our customer services via marinerstv@streamamg.com who can investigate whether there is a problem with the broadcast. If there are no issues at the broadcast end, we&#x27;ll ask you to confirm some details about the device, software and your internet connection. Please note that it is your responsibility to ensure you are using compatible technology ahead of purchasing a Mariners TV subscription.<br />
            <br />
                <strong>How do I get a refund?<br />
            </strong>Refunds can only be issued for people who have reported a technical issue during the game, and it proves to be a fault of our providers and cannot be rectified. Please note that if you do not watch a stream through choice, or don&#x27;t report a technical issue to customer services during the game, we will not be able to issue a refund. Refunds will not be issued for supporters attempting to access via a VPN or using a smartphone or tablet browser.<br />
            <br />
                <strong>Why don&#x27;t fans in the UK and Republic of Ireland have the same access to Mariners TV as international fans?<br />
            </strong>This is due to the EFL&#x27;s existing broadcast agreement with Sky Sports.<br />
            <br />
                <strong>How do I subscribe to Mariners TV?<br />
            </strong>Visit the website: https://marinerstv.co.uk/<br />
            You can only sign up to Mariners TV via the Official Mariners TV website, by &#x27;Live Stream&#x27; on the menu along the top of the website.<br />
            <br />
            <strong>Are Carabao Cup games available?<br />
            </strong>No, they are not permitted under EFL TV broadcast regulations.<br />
            <br />
                <strong>Are FA Cup games available?<br />
            </strong>No, they are not permitted under FA broadcast regulations.<br />
            <br />
                Please note that fixtures may also be selected by international rights holders apart from Sky Sports. These will also be excluded from live streaming.<br />
            <br />
            <strong>Can I watch the live stream games in my local pub/bar?<br />
            </strong>No. It is for private, individual use only. Commercial premises are excluded from the live streaming service. The EFL has a strict monitoring policy in operation and will prosecute any premises illegally streaming live games.<br />
            <br />
            <strong>Can I get an illegal feed?<br />
            </strong>All feeds of the game are monitored by the club and the EFL. The EFL has a strict monitoring policy in operation and will prosecute individuals who illegally stream live games.<br />
            <br />
                <strong>If I live in the UK or Republic of Ireland and subscribe to Mariners TV, will I be able to watch international games when travelling abroad?<br />
            </strong>Domestic fans travelling overseas will have the same access as international users only if they buy an international subscription to Mariners TV once abroad. This is because the UK and Ireland subscriptions have different entitlements to the international subscriptions and you can only access international subscriptions outside of the UK &amp; Ireland.<br />
            For international fans travelling to the UK &amp; Ireland you won&#x27;t be able to view live streams that you are eligible for as your IP address when connecting to the internet will identify you as not being in an eligible country.<br />
            <br />
            <strong>My feed keeps buffering. What&#x27;s wrong?<br />
            </strong>Is your connection fast enough? Try to stop all current downloads, videos you are watching or torrents and visit https://www.speedtest.net. Test your download speed. Results should show a minimum of at least 2Mbps to process streamed content along with usual browsing activities efficiently.<br />
            <br />
                <strong>I&#x27;m receiving a &quot;media not found&quot; message<br />
            </strong>The live video or audio commentary player will only be live during a match. If the game has kicked off and you are receiving this message, please contact marinerstv@streamamg.com.<br />
            <br />
                <strong>How do I cancel my subscription?<br />
            </strong>To cancel your subscription, please email marinerstv@streamamg.com with your cancellation request.<br />
            Please be advised that three working days&#x27; notice is required for cancellation. Cancellation within this period may result in your being charged for the next period.<br />
            <br />
                <strong>I&#x27;ve forgotten my Username / Password<br />
            </strong>If you&#x27;ve forgotten your username and / or password then please click on the relevant links on the login screen.<br />
            If you have any further queries, please contact customer services at marinerstv@streamamg.com and you will receive an email with your Username / Password reminder.<br />
            <br />
                <strong>What happens if no games are shown in my subscription period?<br />
            </strong>If you are on a monthly package, it is an accepted risk that some months will have more live fixtures than others, so no refund will be given in those cases. While we realise that this might be frustrating, we do not increase costs in months that have lots of games and this is an accepted risk by the club. We believe it will balance out over the course of the season.<br />
            <br />
            <strong>Does my live video subscription allow access to live audio commentary when a match cannot be broadcast?<br />
            </strong>Yes, your live video subscription includes all audio streams. So, if a game is not available to watch live, you can always listen to it.</p>
        </div>
    );
}
