import { Link } from 'react-router-dom';

export default function LiveSubscribe() {
    return (
        <div className="wf-section">
            <h5 className="heading">You don't appear to have any subscriptions to view live streams</h5>
            <p className="paragraph-2">Please subscribe to a live stream package to gain access to Live match day video or audio on your device.</p>
            <div className="columns-center">
                <Link className="button-4 w-button" to="/packages">Subscribe</Link>
            </div>
        </div>
    );
}
