// StreamAMG video streaming
import { addDays, lightFormat, parseJSON } from 'date-fns';
import { ReplaySubject } from 'rxjs';
import loadCachedScript from 'utils/load_cached_script';
import querystring from 'utils/querystring';

/**
 * Player Error
 */
export class PlayerError extends Error {
    constructor(message) {
        super(message);
        this.name = 'PlayerError';
    }
}

/**
 * Player API
 */
class Player {
    constructor() {
        this.sessionState = null;
        this.stateObservable = new ReplaySubject(1);

        // Start downloading required scripts
        this.playerReady = loadCachedScript(window.tvConfig.playerJs)
            .toPromise()
            .then(() => {
                window.kWidget.addReadyCallback((targetId) => {
                    this.getCustomerId()
                        .then(customerId => {
                            var kdp = document.getElementById(targetId);
                            kdp.kBind('playerReady', () => {
                                kdp.sendNotification('izsession', customerId);

                                if (process.env.NODE_ENV === 'development') {
                                    console.log('Player.constructor has signalled izsession', targetId, customerId);
                                }
                            });
                        });
                });

                if (process.env.NODE_ENV === 'development') {
                    console.log('Player.constructor has initialised the player SDK');
                }
            });

        // This is a hack around the fact the callback is STORED when calling doLogin and after a redirect, the callback is called during init()
        // For a supposed API, this is insane
        window.playerAPILoginCallback = this._completeLogin;

        this.apiReady = loadCachedScript(window.tvConfig.paymentsJs)
            .toPromise()
            .then(() => new Promise(resolve => {
                if (process.env.NODE_ENV === 'development') {
                    let oldResolve = resolve;
                    resolve = () => {
                        console.log('Player.constructor has initialised the payments SDK');
                        oldResolve();
                    };
                }

                if (window.tvConfig.authenticationProvider == 'SSO') {
                    // Whenever token renews, let's update the client, and update it immediately (this will pop when it's ready)
                    let resolved = false;
                    import(/* webpackChunkName: "js/sso" */ 'API/sso').then(SSO => {
                        SSO.subscribeJWT({
                            next: accessToken => {
                                window.streamPayments.init({
                                    'jwtToken': accessToken,
                                    'language': 'en'
                                });

                                this._updateSessionState(`${window.tvConfig.profileUrl}?lang=en&apijwttoken=${accessToken}`).then(() => {
                                    // Only resolve initial wait on API ready
                                    // Subsequent calls here are just streaming updates to session state that will trigger cascade elsewhere
                                    if (!resolved) {
                                        resolved = true;
                                        resolve();
                                    }
                                });
                            }
                        });
                    });
                } else {
                    // This is non-SSO and we are using doLogin and payments as-is - just init
                    window.streamPayments.init({
                        'language': 'en'
                    });

                    // If login kicked in we have a session already
                    if (this.sessionState) {
                        resolve();
                    } else {
                        this._updateSessionState(`${window.tvConfig.profileUrl}?lang=en`).then(resolve);
                    }
                }
            }));
    }

    /**
     * Clear cache
     */
    clearCache() {
        // If we just purchased packages on the success URL, clear Stream session cache in local storage
        // We have to do this because it only clears it when A links are clicked (they anticipate package clicks), and only A links created before init()
        // For now, clear local storage manually
        // @see CloudPay PaymentsJS
        window.localStorage.removeItem('StreamPaymentSessionData');
    }

    /**
     * Login
     *
     * @param {String} emailaddress
     * @param {String} password
     */
    doLogin(emailaddress, password) {
        this._setSessionState(false);
        this.apiReady
            .then(() => {
                // Callback is stored in local storage, so make sure it can be serialized
                function callback(data) {
                    // Forward to our instance - which could be on a different page load so we cannot use "this"
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Player.doLogin serialized callback forwarding to instance', data);
                    }
                    window.playerAPILoginCallback(data);
                }

                if (process.env.NODE_ENV === 'development') {
                    console.log('Player.doLogin starting', emailaddress);
                }
                window.streamPayments.doLogin({ emailaddress, password }, callback);
            });
    }

    /**
     * Check email valid
     *
     * @param {String} emailaddress
     * @param {(bool) => {}} callback
     */
    isEmailAddressValid(emailaddress, callback) {
        this.apiReady
            .then(() => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Player.isEmailAddressValid starting', emailaddress);
                }
                window.streamPayments.isEmailAddressValid({ emailaddress }, callback);
            });
    }

    /**
     * Logout
     *
     * @return {Promise}
     */
    doLogout() {
        this._setSessionState(false);
        return this.apiReady
            .then(() => new Promise(resolve => {
                window.streamPayments.doLogout(
                    this._getApiOptions(),
                    () => {
                        this._updateSessionState()
                            .then(() => {
                                resolve();
                            });
                    }
                );
            }));
    }

    /**
     * Handle completed login
     * Can occur during current page cycle, or possibly after a login redirect then during init()
     */
    _completeLogin = (data) => {
        // Pump the login result out
        if (process.env.NODE_ENV === 'development') {
            console.log('Player._completeLogin forwarding state', data);
        }
        this._setSessionState(data, `${window.tvConfig.profileUrl}?lang=en`);
    };

    /**
     * Update session state
     *
     * @param {String} profileUrl
     */
    _updateSessionState(profileUrl) {
        return new Promise(resolve => {
            window.streamPayments.getSessionState(
                this._getApiOptions(),
                state => {
                    this._setSessionState(state, profileUrl);

                    if (process.env.NODE_ENV === 'development') {
                        console.log('Player.constructor has loaded the session state', this.sessionState);
                    }

                    resolve();
                }
            );
        });
    }

    /**
     * Subscribe to authentication state changes
     *
     * @param {Object} options Options for the subscription, should contain at least a next property that will be called with the state every time it changes whilst the subscription is active
     * @return {Subscription} Rxjs subscription which you can call unsubscribe() on
     */
    subscribeState(options) {
        return this.stateObservable.subscribe(options);
    }

    /**
     * Get subscription package list
     *
     * @return {Promise} Resolves with the package list
     */
    getSubscriptionPackageList() {
        return this.apiReady
            .then(() => new Promise(resolve => {
                window.streamPayments.getSubscriptionPackageList(
                    this._getApiOptions(),
                    result => {
                        if (process.env.NODE_ENV === 'development') {
                            console.log('Player.getSubscriptionPackageList has completed', result);
                        }

                        resolve(result.SubscriptionPlanOptions);
                    }
                );
            }));
    }

    /**
     * Get a session key that allows you to play secured videos
     *
     * @param {String} entryId
     * @return {Promise} Resolves with a KSession token, or rejects with a String error message
     */
    getKSession(entryId) {
        return this.apiReady
            .then(() => new Promise((resolve, reject) => {
                window.streamPayments.getKSession(
                    { entryId, ...this._getApiOptions() },
                    result => {
                        if (process.env.NODE_ENV === 'development') {
                            console.log('Player.getKSession has completed', result);
                        }

                        let error = null;
                        switch (result.Status) {
                            case -1:
                                console.log('Player.getKSession successful', result.KSession);
                                break;
                            case 0:
                                // Not authenticated
                                error = 'Please login to access this content.';
                                break;
                            case 1:
                                // No subscriptions
                                error = 'We\'re sorry, you need to have a subscription to access this content.';
                                break;
                            case 2:
                                // Has subscription but entitlements don't match
                                error = 'Unfortunately, you don\'t have the right permission/subscription to view this content. You can check for eligible streams in the \'passes\' section of the website.';
                                break;
                            case 3:
                                // Blocked by administrator
                                error = 'We\'re sorry, your access is currently being restricted. Please contact us for more information.';
                                break;
                            case 4:
                                // Failed concurrency check
                                error = 'We\'re sorry, we can only stream content to you on one machine. Please contact us for more information.';
                                break;
                            default:
                                // Unknown
                                error = 'We\'re sorry, this content is currently unavailable.';
                        }

                        if (error) {
                            console.log('Player.getKSession failed', entryId, error);
                            reject(new PlayerError(error));
                            return;
                        }

                        resolve(result.KSession);
                    }
                );
            }));
    }

    /**
     * Get customer ID
     *
     * @return {Promise} Resolves with the customer ID or null if not authenticated
     */
    getCustomerId() {
        return this.apiReady
            .then(() => {
                if (this.sessionState?.data && this.sessionState.data.CurrentCustomerSession) {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Player.getCustomerId has completed', this.sessionState.data.CurrentCustomerSession.CustomerId);
                    }

                    return this.sessionState.data.CurrentCustomerSession.CustomerId;
                }

                if (process.env.NODE_ENV === 'development') {
                    console.log('Player.getCustomerId has completed', null);
                }
                return null;
            });
    }

    /**
     * Embeds a player into the DIV with the given ID.
     * Call destroy() with same targetId to remove it.
     * Returns a promise that might reject if the video cannot be played, with an error message to display to the user
     *
     * Player HTML should be as follows, with the wrapping DIV corresponding to the targetId you give to this method.
     *
     * <div id="kaltura_player_1497856453" style="width: 1200px; height: 711px;" itemprop="video" itemscope itemtype="http://schema.org/VideoObject">
     *   <span itemprop="name" content="Test"></span>
     *   <span itemprop="description" content=""></span>
     *   <span itemprop="duration" content="0"></span>
     *   <span itemprop="width" content="1200"></span>
     *   <span itemprop="height" content="711"></span>
     * </div>
     *
     * @param {String} targetId
     * @param {String} entryId
     * @param {Object} options Options: skipKs/Boolean, If true, we do not generate or send any KS; ks/String, Secure session identifier, if empty or omitted we request one from payment platform; drm/Boolean, DRM player needed if true
     * @return {Promise} Resolves when player successfully initialised, or rejects with a String error
     */
    embedPlayer(targetId, entryId, options = {}) {
        let embedOptions = {
            targetId: targetId,
            wid: '_' + window.tvConfig.partnerID,
            flashvars: { streamerType: 'auto', autoPlay: true },
            entry_id: entryId
        };

        if (options.drm) {
            // DRM support in this one
            embedOptions.uiconf_id = window.tvConfig.drmPlayerUIConfID;
        } else {
            // No DRM here and recommended player
            embedOptions.uiconf_id = window.tvConfig.playerUIConfID;
        }

        if (!options.skipKs) {
            if (options.ks) {
                embedOptions.flashvars.ks = options.ks;
            } else {
                return this.getKSession(entryId)
                    .then(KSession => {
                        embedOptions.flashvars.ks = KSession;

                        return this.playerReady.then(() => {
                            // Copy before output as Kaltura modifies it
                            console.log('Player.embedPlayer', { ...embedOptions, flashvars: { ...embedOptions.flashvars } });
                            window.kWidget.embed(embedOptions);

                            if (process.env.NODE_ENV === 'development') {
                                console.log('Player.embedPlayer with KS has completed', targetId, KSession);
                            }
                        });
                    });
            }
        }

        return this.playerReady.then(() => {
            window.kWidget.embed(embedOptions);

            if (process.env.NODE_ENV === 'development') {
                console.log('Player.embedPlayer has completed', targetId);
            }
        });
    }

    /**
     * Destroy video in the DIV with the given ID
     *
     * @param {string} targetId
     */
    destroy(targetId) {
        this.playerReady.then(() => {
            window.kWidget.destroy(targetId);

            if (process.env.NODE_ENV === 'development') {
                console.log('Player.destroy has completed', targetId);
            }
        });
    }

    /**
     * Internal
     *
     * Set session state
     */
    _setSessionState(state, profileUrl = '') {
        this.sessionState = this._processState(state ? state : false, profileUrl);
        this.stateObservable.next(this.sessionState);
        if (!state) {
            // Logged out so we should not have a token stored
            window.localStorage.removeItem('StreamAuthenticationToken');
        } else if (state.AuthenticationToken) {
            // Token is not always provided - it only is provided in the result to _completeLogin and we need to remember it
            window.localStorage.setItem('StreamAuthenticationToken', state.AuthenticationToken);
        }
    }

    /**
     * Process a state and extra any messages we need to show to the user
     *
     * @param {Object} state
     * @param {String} profileUrl
     * @return {Object}
     */
     _processState(state, profileUrl) {
        // Do we need to display any messages to the user?
        if (!state || state.CurrentCustomerSessionStatus !== -1) {
            return { data: state, messages: [], profileUrl };
        }

        let messages = [];

        // If successful purchase (queryparam in URL) add a message
        const query = querystring.parse();
        if (query.purchase === 'success') {
            messages.push({
                key: 'purchase_success',
                type: 'success',
                title: 'Success',
                message: 'Package successfully purchased.',
            });
        }

        if (state.CurrentCustomerSession.CustomerBillingProfileLastFailedAt) {
            let lastFailure = parseJSON(state.CurrentCustomerSession.CustomerBillingProfileLastFailedAt);
            if (!isNaN(lastFailure)) {
                messages.push({
                    key: 'billing_failure',
                    type: 'alert',
                    title: 'Warning',
                    message: `The last billing attempt on ${lightFormat(lastFailure, 'dd/MM/yyyy')} failed.`,
                    linkText: 'Visit account',
                    linkUrl: profileUrl,
                    persistent: true
                });
            }
        }

        if (state.CurrentCustomerSession.CustomerBillingProfileExpiresAt) {
            let expiryDate = parseJSON(state.CurrentCustomerSession.CustomerBillingProfileExpiresAt);
            if (!isNaN(expiryDate)) {
                if (expiryDate < new Date()) {
                    messages.push({
                        key: 'payment_invalid',
                        type: 'warning',
                        title: 'Warning',
                        message: `Your current payment method expired on ${lightFormat(expiryDate, 'dd/MM/yyyy')} and is no longer valid.`,
                        linkText: 'Update details',
                        linkUrl: profileUrl,
                        persistent: true
                    });
                } else if (expiryDate < addDays(new Date(), 30)) {
                    messages.push({
                        key: 'payment_expire',
                        type: 'warning',
                        title: 'Warning',
                        message: `Your current payment method is due to expire in the next 30 days on ${lightFormat(expiryDate, 'dd/MM/yyyy')}.`,
                        linkText: 'Update details',
                        linkUrl: profileUrl,
                        persistent: true
                    });
                }
            }
        }

        return { data: state, messages, profileUrl };
    }

    /**
     * Get API options
     *
     * Needed for standard sessions (non-JWT), as the cookie will be blocked by cross-site tracking prevention
     *
     * @return {Object}
     */
    _getApiOptions() {
        let options = {};
        if (window.tvConfig.authenticationProvider == 'SSO') {
            // apisessionid not needed for JWT
            return options;
        }

        let apisessionid = window.localStorage.getItem('StreamAuthenticationToken');
        if (apisessionid) {
            options.apisessionid = apisessionid;
        }
        return options;
    }
}

export default new Player();
