import { thumbnailForSize } from 'utils/thumbnail';

export default function HighlightsItem(props) {
    const { item } = props;
    const { mediaData, metaData } = item;
    const { entryId, thumbnailUrl } = mediaData;
    const { title, categories } = metaData;

    const url = `/player/${entryId}`;

    return (
        <div className="columns-2 w-row">
            <div className="w-col w-col-6">
                <div className="video w-video w-embed">
                    <a href={url}><img src={thumbnailForSize(thumbnailUrl, 1024, 576)} /></a>
                </div>
            </div>
            <div className="w-col w-col-6">
                {categories.length ? <p className="paragraph-3">{categories[0]}</p> : null}
                <p className="paragraph-4"><a className="plain-link" href={url}><strong>{title}</strong></a></p>
            </div>
        </div>
    );
}
